import React, { useState, useEffect } from 'react'
import { Doughnut, Bar } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js'
import { HiUsers, HiChartPie, HiUserGroup, HiScale } from 'react-icons/hi'
import { API_URL_ANALYTICS } from '../../config/config';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, Legend)

const ANALYTICS_API_URL = API_URL_ANALYTICS;


const LOCATIONS = [
  "Nakuru",
  "Kibra",
  "Machakos(Athi River)",
  "Nyandarua", 
  "Kakamega",
  "Narok",
  "Nairobi (TradeFair)",
  "Trans-Nzoia",
  "Meru",
  "Mombasa",
  "Garissa",
  "Kirinyaga",
  "mathare",
  "Machakos-vota",
  "Bungoma",
  "Kiambu-githurai",
  "Kajiado",
  "Nairobi-kabete",
  "Bomet",
  "Kisii",
  "Taita-Taveta",
  "Murang'a",
  "Homa Bay",
  "Turkana",
  "Kericho(Kapcheluch)",
  "Tharaka-Nithi",
  "Nyamira",
  "Uasin_Gishu",
  "Kiambu(Kamandura)",
  "Kwale",
  "Isiolo",
  "Baringo",
  "Makueni",
  "Vihiga",
  "Niavasha(Safari-Rally)",
  "Marsabit"
];


function PatientChartAnalysis() {
 const [selectedLocation, setSelectedLocation] = useState('')
 const [locations, setLocations] = useState([])
 
 const [metrics, setMetrics] = useState({
   sha: null,
   gender: null,
   age: null,
   population: null
 })
 const [isLoading, setIsLoading] = useState(true)


 useEffect(() => {
   fetch(`${ANALYTICS_API_URL}/api/analytics/location`)
     .then(res => res.json())
     .then(data => {
       setLocations(data.filter(loc => loc._id))
     })
 }, [])

 useEffect(() => {
   const query = selectedLocation ? `?location=${selectedLocation}` : ''
   setIsLoading(true)

   Promise.all([
     fetch(`${ANALYTICS_API_URL}/api/analytics/sha${query}`).then(res => res.json()),
     fetch(`${ANALYTICS_API_URL}/api/analytics/demographics${query}`).then(res => res.json())
   ])
   .then(([shaData, demoData]) => {
     const shaStats = shaData.details
     
     const genderCount = { male: 0, female: 0 }
     const ageGroups = { '0-18': 0, '19-30': 0, '31-50': 0, '51-70': 0, '70+': 0 }
     
     demoData.forEach(item => {
       if (item._id.gender) {
         genderCount[item._id.gender.toLowerCase()] += item.count
         if (item._id.ageGroup) {
           ageGroups[item._id.ageGroup] += item.count
         }
       }
     })

     setMetrics({
       sha: {
         labels: shaStats.map(s => s.status),
         data: shaStats.map(s => s.count)
       },
       gender: {
         labels: ['Male', 'Female'],
         data: Object.values(genderCount)
       },
       age: {
         labels: Object.keys(ageGroups),
         data: Object.values(ageGroups)
       },
       population: selectedLocation ? 
         locations.find(l => l._id === selectedLocation)?.count : 
         locations.reduce((acc, curr) => acc + curr.count, 0)
     })
     setIsLoading(false)
   })
 }, [selectedLocation, locations])

 const chartOptions = {
   responsive: true,
   maintainAspectRatio: false,
   plugins: {
     legend: { 
       position: 'bottom',
       labels: {
         usePointStyle: true,
         padding: 20,
         font: { size: 12 }
       }
     },
     tooltip: {
       backgroundColor: 'rgba(0,0,0,0.8)',
       padding: 12,
       bodyFont: { size: 14 },
       callbacks: {
         label: function(context) {
           const value = context.raw
           const total = context.dataset.data.reduce((a, b) => a + b, 0)
           const percentage = ((value / total) * 100).toFixed(1)
           return `${context.label}: ${value.toLocaleString()} (${percentage}%)`
         }
       }
     }
   }
 }

 return (
   <div className="p-4">
     <div className="mb-6 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
       <h1 className="text-xl sm:text-2xl font-bold">
         Analytics Dashboard
         {selectedLocation && 
           <span className="text-gray-500 ml-2">• {selectedLocation}</span>
         }
       </h1>
       <select 
  value={selectedLocation}
  onChange={(e) => setSelectedLocation(e.target.value)}
  className="w-full sm:w-auto p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
>
  <option value="">All Locations</option>
  {LOCATIONS.map(location => (
    <option key={location} value={location}>{location}</option>
  ))}
</select>
     </div>

     <div className="bg-white p-6 rounded-lg border mb-2 shadow hover:shadow-lg transition-shadow duration-300">
           <h2 className="text-sm font-semibold mb-6">Key Insights</h2>
           <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
             <div className="flex items-center">
               <div className="p-3 bg-blue-100 rounded-lg">
                 <HiUsers className="h-6 w-6 text-blue-600" />
               </div>
               <div className="ml-4">
                 <p className="text-sm font-medium text-gray-600">Total Population</p>
                 <p className="text-lg font-bold">{metrics.population?.toLocaleString()}</p>
               </div>
             </div>

             <div className="flex items-center">
               <div className="p-3 bg-green-100 rounded-lg">
                 <HiChartPie className="h-6 w-6 text-green-600" />
               </div>
               <div className="ml-4">
                 <p className="text-sm font-medium text-gray-600">SHA Coverage</p>
                 <p className="text-lg font-bold">
                   {((metrics.sha?.data[1] / metrics.population) * 100).toFixed(1)}%
                 </p>
               </div>
             </div>

             <div className="flex items-center">
               <div className="p-3 bg-purple-100 rounded-lg">
                 <HiUserGroup className="h-6 w-6 text-purple-600" />
               </div>
               <div className="ml-4">
                 <p className="text-sm font-medium text-gray-600">Gender Ratio (M:F)</p>
                 <p className="text-lg font-bold">
                   {((metrics.gender?.data[0] / metrics.gender?.data[1]) || 0).toFixed(2)}
                 </p>
               </div>
             </div>

             <div className="flex items-center">
               <div className="p-3 bg-orange-100 rounded-lg">
                 <HiScale className="h-6 w-6 text-orange-600" />
               </div>
               <div className="ml-4">
                 <p className="text-sm font-medium text-gray-600">Dominant Age Group</p>
                 <p className="text-lg font-bold">
                   {metrics.age?.labels[
                     metrics.age.data.indexOf(Math.max(...metrics.age.data))
                   ]}
                 </p>
               </div>
             </div>
           </div>
         </div>


     {isLoading ? (
       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
         {[1,2,3,4,5].map(i => (
           <div key={i} className="bg-white p-6 rounded-lg shadow animate-pulse">
             <div className="h-64 bg-gray-200 rounded"></div>
           </div>
         ))}
       </div>
     ) : (
       <>
         <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
           {metrics.sha && (
             <div className="bg-white p-6 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
               <h2 className="text-sm font-semibold mb-4">SHA Coverage</h2>
               <div className="h-64">
                 <Doughnut 
                   data={{
                     labels: metrics.sha.labels,
                     datasets: [{
                       data: metrics.sha.data,
                       backgroundColor: ['#60A5FA', '#F87171', '#FBBF24']
                     }]
                   }}
                   options={chartOptions}
                 />
               </div>
             </div>
           )}
           
           {metrics.gender && (
             <div className="bg-white p-6 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
               <h2 className="text-sm font-semibold mb-4">Gender Distribution</h2>
               <div className="h-64">
                 <Doughnut 
                   data={{
                     labels: metrics.gender.labels,
                     datasets: [{
                       data: metrics.gender.data,
                       backgroundColor: ['#60A5FA', '#F87171']
                     }]
                   }}
                   options={chartOptions}
                 />
               </div>
             </div>
           )}
         </div>

         <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
           <div className="lg:col-span-2 bg-white p-6 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
             <h2 className="text-sm font-semibold mb-4">Top Locations</h2>
             <div className="h-64">
               <Bar 
                 data={{
                   labels: locations
                     .sort((a, b) => b.count - a.count)
                     .slice(0, 8)
                     .map(loc => loc._id),
                   datasets: [{
                     data: locations
                       .sort((a, b) => b.count - a.count)
                       .slice(0, 8)
                       .map(loc => loc.count),
                     backgroundColor: '#60A5FA',
                     borderRadius: 6
                   }]
                 }}
                 options={{
                   ...chartOptions,
                   indexAxis: 'y',
                   plugins: { 
                     legend: { display: false }
                   },
                   scales: {
                     x: {
                       grid: { display: false }
                     },
                     y: {
                       grid: { display: false }
                     }
                   }
                 }}
               />
             </div>
           </div>

           {metrics.age && (
             <div className="bg-white p-6 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
               <h2 className="text-sm font-semibold mb-4">Age Distribution</h2>
               <div className="h-64">
                 <Doughnut 
                   data={{
                     labels: metrics.age.labels,
                     datasets: [{
                       data: metrics.age.data,
                       backgroundColor: [
                         '#60A5FA',
                         '#34D399',
                         '#F59E0B',
                         '#EC4899',
                         '#8B5CF6'
                       ]
                     }]
                   }}
                   options={chartOptions}
                 />
               </div>
             </div>
           )}
         </div>


       </>
     )}
   </div>
 )
}

export default PatientChartAnalysis