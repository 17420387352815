// config.js
const API_URL_PATIENTS = 'https://medicalcampapi.zuri.health/api/patients' //'http://localhost:8081/api/patients';
const API_URL_MAIN =  'https://procurementmanager.zuri.health/api/v1';


const API_URL_ANALYTICS = 'https://camps-api.zuri.health';

const API_KEY = 'zuri-procurement-manager-vi_api_key_1a2b3c4d5e6f7g8h9i0j'
const SECRET_KEY =
  'f9d8e6c7b9a0f2e1c5d8b7a4e9c3d2f0a7b6c8e1d0a9f5b7c4e6a2f8d9c0b7a1'

export { API_URL_PATIENTS, API_URL_ANALYTICS, API_URL_MAIN, API_KEY, SECRET_KEY }
