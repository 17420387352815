import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTachometerAlt,
  faChartLine,
  faBars,
  faTimes,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const userRole = user?.role || 'Staff'

  return (
    <div className="relative h-screen">
      {/* Hamburger Menu */}
      <div className="flex items-center p-4 lg:hidden">
        <button 
          onClick={() => setIsOpen(!isOpen)} 
          className="text-gray-900"
        >
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} className="h-6 w-6" />
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed left-0 top-0 z-30 flex h-screen w-64 transform flex-col bg-gray-100 transition-transform duration-300 ease-in-out lg:sticky ${
          isOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
        }`}
      >
        {/* Logo Section */}
        <div className="flex items-center justify-center p-4">
          <img
            src="https://storage.cloud.google.com/healthcamp-products/Frame 6322.svg"
            alt="Logo"
            className="h-12 w-auto"
          />
        </div>

        {/* Navigation */}
        <nav className="mt-10 flex-1">
          <ul className="space-y-2 px-4">
            {(userRole === 'Admin' || userRole === 'Manager' || userRole === 'Staff') && (
              <>
                <li>
                  <Link
                    to="home"
                    className="mx-auto flex w-full items-center justify-center rounded-md bg-brandPrimary p-3 text-white transition-colors duration-300 hover:bg-brandSecondary"
                  >
                    <FontAwesomeIcon icon={faTachometerAlt} className="mr-3" />
                    <span>Dashboard</span>
                  </Link>
                </li>

                <li>
                  <Link
                    to="patient-analysis"
                    className="mx-auto flex w-full items-center justify-center rounded-md bg-brandPrimary p-3 text-white transition-colors duration-300 hover:bg-brandSecondary"
                  >
                    <FontAwesomeIcon icon={faChartLine} className="mr-3" />
                    <span>Analysis</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>

        {/* User Section */}
        <div className="p-4">
          <div className="rounded-md bg-gray-200 p-3">
            <p className="text-sm font-medium text-gray-900">{user?.firstName || 'User'}</p>
            <p className="text-xs text-gray-600">{userRole}</p>
          </div>
        </div>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div 
          className="fixed inset-0 z-20 bg-black bg-opacity-50 lg:hidden"
          onClick={() => setIsOpen(false)}
        />
      )}
    </div>
  )
}

export default Sidebar
