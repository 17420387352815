import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addWellness } from '../../actions/wellnessActions'

const WellnessRegistrationForm = () => {
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    idNumber: '',
    insuranceMemberNumber: '',
    email: '',
    location: '',
    dateOfBirth: '', // New field for date of birth
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await dispatch(addWellness(formData))

      // Clear the form data after submission
      setFormData({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        idNumber: '',
        insuranceMemberNumber: '',
        email: '',
        location: '',
        dateOfBirth: '', // Clear date of birth as well
      })
    } catch (error) {
      // Optional: Log the error for debugging if needed
      console.error(error)
    }
  }

  // Define which fields are required
  const requiredFields = {
    firstName: true,
    lastName: true,
    phoneNumber: true,
    idNumber: true,
    insuranceMemberNumber: false,
    email: false,
    location: false,
    dateOfBirth: true, // Make dateOfBirth required
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gradient-to-r from-[#26CED1] to-[#EA516F] p-4 md:p-6 lg:p-8">
      <div className="w-full max-w-md rounded-lg border border-gray-200 bg-white p-6 shadow-lg md:max-w-lg md:p-8">
        <div className="mb-6 text-center">
          <img
            src="https://storage.cloud.google.com/healthcamp-products/Frame 6322.svg"
            alt="Health Camp Logo"
            className="mx-auto h-auto w-24 md:w-32"
          />
        </div>
        <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
          {Object.keys(formData).map((key) => (
            <div key={key} className="flex flex-col">
              <label className="flex items-center text-sm font-semibold capitalize text-gray-700">
                {key.replace(/([A-Z])/g, ' $1').trim()}
                {requiredFields[key] && (
                  <span className="ml-1 text-red-500">*</span>
                )}
              </label>
              {key === 'dateOfBirth' ? (
                <input
                  type="date"
                  name={key}
                  value={formData[key]}
                  onChange={handleChange}
                  className="mt-1 rounded-md border border-gray-300 p-3 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-500"
                  required={requiredFields[key]}
                />
              ) : (
                <input
                  type="text"
                  name={key}
                  value={formData[key]}
                  onChange={handleChange}
                  className="mt-1 rounded-md border border-gray-300 p-3 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-500"
                  required={requiredFields[key]}
                />
              )}
            </div>
          ))}
          <button
            type="submit"
            className="w-full rounded-md bg-[#EA516F] py-2 text-white transition duration-150 ease-in-out hover:bg-[#26CED1] md:py-3"
          >
            Register
          </button>
        </form>
        <div className="mt-4 flex justify-center text-sm text-gray-600 md:mt-6">
          <p className="px-4 text-center md:px-0">
            Your personal details are kept private and will not be shared with
            third parties. We respect your privacy and ensure your data is
            handled securely in accordance with our privacy policy.
          </p>
        </div>
        <footer className="mt-4 text-center text-sm text-gray-600 md:mt-6">
          <p>&copy; Zuri Health 2024. All rights reserved.</p>
        </footer>
      </div>
    </div>
  )
}

export default WellnessRegistrationForm
